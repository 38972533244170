<template>
  <ion-item>
    <ion-label :position="tipoAdversidad ? 'stacked' : 'floating'">
      Tipo de Adversidad
    </ion-label>
    <ion-select
      :value="tipoAdversidad"
      @ionChange="tipoAdversidad = $event.target.value"
      :selected-text="
        tipoAdversidad && tipoAdversidad.nombre
          ? tipoAdversidad.nombre
          : undefined
      "
    >
      <ion-select-option v-for="t in tipos" :value="t" :key="t._id">
        {{ t.nombre }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating">
      Fecha
    </ion-label>
    <ion-datetime
      v-model="fecha"
      :value="fecha"
      display-timezone="Etc/GMT+3"
      display-format="DD/MM/YY"
      picker-format="DD/MM/YY"
      cancelText="Cancelar"
      doneText="Aceptar"
    ></ion-datetime>
  </ion-item>
  <ion-item>
    <ion-label position="floating">Momento</ion-label>
    <ion-select :value="momento" @ionChange="momento = $event.target.value">
      <ion-select-option v-for="m in momentos" :value="m" :key="m">
        {{ m }}
      </ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating">% estimado de daño</ion-label>
    <ion-input
      v-model="porcEstimadoDanio"
      autocapitalize="off"
      type="text"
      inputmode="text"
      autocomplete="off"
      placeholder="porcentaje(%)"
    ></ion-input>
  </ion-item>

  <ion-button
    fill="clear"
    size="large"
    color="danger"
    @click="$emit('delete')"
    v-if="editing"
  >
    <ion-icon :md="icons.trash" :ios="icons.trash"></ion-icon>
  </ion-button>
  <ion-row class="ion-float-right">
    <ion-col size="auto">
      <ion-button
        fill="outline"
        class="ion-margin-top ion-float-end"
        color="medium"
        @click="cancel"
      >
        Cancelar
      </ion-button>
    </ion-col>
    <ion-col size="auto">
      <ion-button
        class="ion-float-right ion-margin-top ion-margin-bottom"
        @click="submitEditOrCreate"
      >
        Aceptar
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonInput,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonLabel,
  alertController,
  IonItem,
  IonIcon,
  IonRow,
  IonCol,
  IonButton
} from "@ionic/vue";
import { mapState } from "vuex";
import { trashOutline } from "ionicons/icons";
import { TiposAdversidades } from "@/api";

export default {
  components: {
    IonInput,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    IonRow,
    IonCol,
    IonButton,
    IonIcon
  },

  props: {
    success: { type: Boolean, default: false },
    error: { type: String, default: "" },
    campaniaCultivo: { type: Object, default: () => ({}) },
    adversidad: { type: Object, default: () => ({}) }
  },

  data: () => ({
    tipoAdversidad: null,
    fecha: "",
    tipos: [],
    porcEstimadoDanio: "",
    momento: "",
    momentos: [
      "Emergencia",
      "Vegetativo Temprano",
      "Vegetativo Tardío",
      "Reproductivo Temprano",
      "Reproductivo Tardío",
      "Madurez Fisiológica"
    ],
    icons: {
      trash: trashOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    editing() {
      return this.adversidad._id;
    }
  },

  async mounted() {
    await this.dataSync();
    if (this.editing) {
      this.$nextTick(() => {
        this.tipoAdversidad = this.adversidad.tipoAdversidad;
      });
    }
  },

  watch: {
    success(val) {
      if (val) {
        this.cleaner();
      }
    },
    async adversidad() {
      this.cleaner();
      await this.dataSync();
    }
  },

  methods: {
    cleaner() {
      this.tipoAdversidad = {};
      this.fecha = "";
      this.momento = "";
      this.porcEstimadoDanio = "";
    },

    cancel() {
      this.cleaner();
      this.$emit("cancel");
    },

    async dataSync() {
      if (this.editing) {
        this.tipoAdversidad = this.adversidad.tipoAdversidad;
        this.porcEstimadoDanio = this.adversidad.porcEstimadoDanio;
        this.momento = this.adversidad.momento;
        this.fecha = new Date(this.adversidad.fecha).toISOString();
      }
      this.tipos = await this.getTiposAdversidades();
    },

    async getTiposAdversidades() {
      const { data, error } = await TiposAdversidades.get();
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Tipos de Adversidades.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    async submitEditOrCreate() {
      this.$emit("update:success", false);
      let error = "";
      if (!this.tipoAdversidad) {
        error += "<li>Elija un Tipo de <b>Adversidad</b>." + `<br/></li>`;
      }
      if (!this.fecha) {
        error += "<li>Complete el campo <b>Fecha</b>." + `<br/></li>`;
      }
      if (!this.momento || !this.momentos.includes(this.momento)) {
        error += "<li>Elija un <b>Momento</b>." + `<br/></li>`;
      }
      if (!this.porcEstimadoDanio) {
        error +=
          "<li>Complete el campo <b>% estimado de daño</b>." + `<br/></li>`;
      } else if (isNaN(this.porcEstimadoDanio)) {
        error +=
          "<li>El campo <b>% estimado de daño </b> es numérico." + `<br/></li>`;
      } else if (this.porcEstimadoDanio < 0 || this.porcEstimadoDanio > 100) {
        error +=
          "<li>El campo <b>% estimado de daño </b> debe ser un valor de porcentaje(%) entre 0 y 100." +
          `<br/></li>`;
      }
      if (!error) {
        this.$emit("submit", {
          adversidad: {
            _id: this.adversidad._id,
            campaniaCultivo: this.campaniaCultivo,
            tipoAdversidad: this.tipoAdversidad,
            fecha: this.fecha,
            momento: this.momento,
            porcEstimadoDanio: this.porcEstimadoDanio
          }
        });
      } else {
        const alert = await alertController.create({
          header: "Error al crear la adversidad",
          message: error,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.error {
  color: red;
  font-size: smaller;
  font-style: italic;
  font-weight: 500;
  margin-top: 4px;
}

.negrita {
  color: black;
  font-size: large;
  margin-left: 4px;
  font-weight: bold;
}

.label {
  font-size: medium;
}

.margen5px {
  margin-left: 5px;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

ion-item {
  --pading-start: 8px;
}

ion-content {
  --background: transparent;
}
</style>
