<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-item
          id="item"
          class="ion-margin-top margen9pxLados itemColor"
          size="auto"
        >
          <ion-row width="200px">
            <ion-col>
              <ion-text color="dark">
                Lote
              </ion-text>

              <br />
              <ion-text
                class="ion-text-capitalize negrita"
                style="white-space: normal"
                color="dark"
              >
                {{ nombreLoteCiudad }}
              </ion-text>
              <br />
              <ion-text color="secondary">
                <i>{{ "Sup. " + hectareasLote + " Ha" }}</i>
              </ion-text>
              <br />
              <ion-text color="dark">Establecimiento:</ion-text>
              <ion-text
                class="ion-text-capitalize"
                style="white-space: normal"
                color="dark"
              >
                <b>{{ nombreEstablecimientoLote }}</b>
              </ion-text>
            </ion-col>
          </ion-row>
        </ion-item>

        <div class="margen8px">
          <ion-grid fixed>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px" id="colCampania">
                <ion-text class="ion-float-right">Campaña:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  class="xLargeFont"
                  style="white-space: normal"
                  :color="campania.esActual ? 'primary' : 'tertiary'"
                >
                  {{ " " + nombreCampania || "" }}
                </ion-text>
              </ion-col>
              <br />
            </ion-row>
            <ion-row class="ion-align-items-center">
              <ion-col size="3" style="margin-top:4px" id="colCultivo">
                <ion-text class="ion-float-right">Cultivo:</ion-text>
              </ion-col>
              <ion-col>
                <ion-text
                  class="xLargeFont"
                  style="white-space: normal"
                  color="primary"
                >
                  {{ " " + cultivoVariedadCampania || "" }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <ion-card v-if="isEditable">
          <ion-row class="ion-align-items-center ion-margin-top">
            <ion-col style="margin-left:27px">
              <ion-text>
                {{ editing ? "Editar Adversidad" : "Crear Adversidad" }}
              </ion-text>
            </ion-col>
            <ion-col size="auto" style="margin-right:16px; padding:0px;">
              <ion-row class="ion-justify-content-end">
                <ion-col size="auto">
                  <ion-row class="ion-justify-content-center">
                    <ion-thumbnail>
                      <ion-img
                        src="assets/icon/fichaLote/icoAdversidad.png"
                      ></ion-img>
                    </ion-thumbnail>
                  </ion-row>
                  <ion-row class="ion-justify-content-center">
                    <ion-text style="font-size:x-small">
                      Adversidad
                    </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-card-content>
            <AdversidadForm
              :error="error"
              :campaniaCultivo="campaniaCultivo"
              :adversidad="selectedAdversidad"
              @submit="submitAdversidad"
              @delete="deleteAdversidad"
              @cancel="volver"
            ></AdversidadForm>
          </ion-card-content>
        </ion-card>

        <ion-button
          v-if="tipoActual === ''"
          @click="volver"
          class="ion-margin-top ion-margin-bottom ion-float-end margen9pxLados"
          color="primary"
        >
          Volver
        </ion-button>

        <br />
        <br />
        <br />
        <br />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  IonButton,
  IonContent,
  IonPage,
  IonCol,
  IonImg,
  IonRow,
  IonCard,
  IonCardContent,
  IonItem,
  IonGrid,
  IonThumbnail,
  IonText,
  alertController
} from "@ionic/vue";
//import http from "@/api/http";
import AdversidadForm from "../../components/AdversidadForm.vue";
import {
  CampaniaCultivos,
  Lotes,
  Adversidades,
  Persona,
  Establecimientos
} from "@/api";
import { addOutline, pencilOutline } from "ionicons/icons";

export default {
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonImg,
    IonThumbnail,
    IonItem,
    IonRow,
    IonCol,
    IonText,
    IonCard,
    IonCardContent,
    IonPage,
    AdversidadForm
  },
  data: () => ({
    success: null,
    campaniaCultivo: {},
    campania: {},
    modalCreate: false,
    cultivo: {},
    productor: {},
    establecimiento: {},
    variedadCultivo: {},
    error: "",
    semillero: {},
    selectedAdversidad: {},
    lote: {},
    nombreLoteCiudad: "",
    hectareasLote: "",
    nombreEstablecimientoLote: "",
    nombreCampania: "",
    cultivoVariedadCampania: "",
    icons: {
      add: addOutline,
      edit: pencilOutline
    }
  }),

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["isAdmin"]),
    editing() {
      return !!this.$route.params.idAdversidad;
    },
    idProductor() {
      return this.$route.params.idProductor;
    },
    persona() {
      if (this.isAdmin && this.idProductor) {
        if (this.productor && this.productor._id) {
          return this.productor;
        }
        return {};
      }
      return this.user;
    },
    txtVariedad() {
      return this.variedadCultivo ? this.variedadCultivo.variedad : "Otra";
    },
    txtSemillero() {
      return this.semillero ? this.semillero.nombre : "Otro";
    },
    isEditable() {
      return this.campaniaCultivo &&
        (this.campaniaCultivo.abierto === true ||
          this.campaniaCultivo.abierto === false)
        ? this.campaniaCultivo.abierto
        : true;
    }
  },

  watch: {
    async editing(val) {
      if (val) {
        await this.dataSync();
      }
    },

    async idProductor() {
      await this.dataSync();
    }
  },

  async mounted() {
    await this.getMe();
    await this.dataSync();
  },

  methods: {
    ...mapActions(["getMe"]),
    volver() {
      if (this.isAdmin && this.idProductor) {
        this.$router.push({
          name: "admin-productor-lote",
          params: {
            idProductor: this.idProductor,
            tab: "Ad",
            idLote: this.lote._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      } else {
        this.$router.push({
          name: "lote",
          params: {
            tab: "Ad",
            idLote: this.lote._id
          },
          query: this.$route.query.campCult
            ? { campCult: this.$route.query.campCult }
            : {}
        });
      }
      //this.$router.back();
    },

    async dataSync() {
      if (this.idProductor && this.isAdmin) {
        this.productor = await this.getProductor(this.idProductor);
      }
      if (this.editing) {
        this.selectedAdversidad = await this.getSelectedAdversidad();
      }
      this.lote = await this.getLote();

      const campaniascultivos = await this.getCampaniaCultivo();
      if (this.$route.query.campCult) {
        const campCultElegida = campaniascultivos.find(
          cc => cc._id === this.$route.query.campCult
        );
        this.campaniaCultivo = campCultElegida
          ? campCultElegida
          : campaniascultivos[0];
      } else {
        const campCultFav = campaniascultivos.find(
          cc => cc.favorito && cc.favorito === true
        );
        this.campaniaCultivo = campCultFav ? campCultFav : campaniascultivos[0];
      }

      this.campania = this.campaniaCultivo.campania || "";
      this.cultivo = this.campaniaCultivo.cultivo || "";
      this.semillero = this.campaniaCultivo.semillero || "";
      this.variedadCultivo = this.campaniaCultivo.variedadCultivo || "";

      this.nombreLoteCiudad =
        this.lote.nombre + " - " + this.lote.localidad || "";
      this.hectareasLote = this.lote.hectareas || "";
      this.nombreCampania = this.campania.nombre || "";
      this.cultivoVariedadCampania =
        this.cultivo.nombre + " ( " + this.txtVariedad + " )" || "";
      if (this.lote.establecimiento) {
        this.establecimiento = await this.getEstablecimiento();
      } else {
        this.establecimiento = { id: "0", nombre: "-" };
      }
      this.nombreEstablecimientoLote = this.establecimiento.nombre || "";
    },

    async getCampaniaCultivo() {
      const { data, error } = await CampaniaCultivos.get({
        query: this.editing
          ? { _id: this.selectedAdversidad.campaniaCultivo }
          : { lote: this.lote._id },
        populate: "campania, cultivo, semillero, variedadCultivo",
        sort: { createdAt: -1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo campaña - cultivo",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getProductor(idProductor) {
      const { data, error } = await Persona.getBy_id(idProductor);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Productor.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getSelectedAdversidad() {
      const { data, error } = await Adversidades.getBy_id(
        this.$route.params.idAdversidad,
        {
          populate: "adversidad, tipoAdversidad"
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message:
            "Error obteniendo Adversidad nro" + this.$route.params.idAdversidad,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getLote() {
      const { data, error } = await Lotes.getBy_id(this.$route.params.idLote);
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo lote nro" + this.$route.params.idLote,
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async getEstablecimiento() {
      const { data, error } = await Establecimientos.getBy_id(
        this.lote.establecimiento
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo establecimiento",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
      return data;
    },

    async deleteAdversidad() {
      const alert = await alertController.create({
        header: "Adversidad",
        message: "¿Desea eliminar esta adversidad?",
        buttons: [
          {
            text: "Si",
            handler: async () => {
              const { error } = await Adversidades.deleteReal(
                this.selectedAdversidad
              );
              if (!error) {
                const alert = await alertController.create({
                  header: "Adversidad",
                  message: "Adversidad Eliminada",
                  buttons: [
                    {
                      text: "Aceptar",
                      handler: () => {
                        this.volver();
                      }
                    }
                  ]
                });
                alert.present();
              }
            }
          },
          {
            text: "No"
          }
        ]
      });
      alert.present();
    },

    async submitAdversidad({ adversidad }) {
      this.error = "";
      const { /*data,*/ error } = await Adversidades.save(adversidad);
      if (error) {
        this.error = error;
      } else {
        this.success = true;
        const alert = await alertController.create({
          header: "Adversidad",
          message: "Adversidad Guardada",
          buttons: [
            {
              text: "Aceptar",
              handler: () => {
                this.selectedAdversidad = {};
                this.volver();
              }
            }
          ]
        });
        alert.present();
      }
    }
  }
};
</script>

<style scoped>
.modal-wrapper {
  background: transparent !important;
}

ion-modal {
  --ion-background-color: #ffffff85;
}

.negrita {
  font-size: 175%;
  margin-left: 0px;
  font-weight: bold;
}

.xLargeFont {
  font-size: x-large;
  font-weight: bold;
}

.margen4px {
  margin-left: 4px;
}

.margen8px {
  margin-left: 8px;
}

.right {
  display: block;
  margin-left: auto;
  margin-right: 8px;
  float: right;
}

.width25 {
  width: 25%;
}

.margen9pxLados {
  margin-left: 9px;
  margin-right: 9px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.itemColor {
  --background: rgba(var(--ion-color-primary-rgb), 0.6);
  --border-color: rgba(var(--ion-color-primary-rgb), 0.6);
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}
</style>
